import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@20.16.5_babel-plugin-macros@3.1.0_sass@1.7_rqbbf4tbztzkhlgsexxzkssfyq/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2Flibs%2Ftoby%2Fdist%2Fcss-vars.css.js.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA9WXS2%2FjNhCA7%2F0VAwgLZQHaoKj39NSmDdpDcoi7vVMS6ciRRUWSEyeL%2FvdilO6uKLGAD0XRBqDgfBzOixyOhL0xI3z%2BDmCzeUwzxQOOkHXn72ckQAgSGwmEyCYhQtCdYTBNXYGneKVUOJ%2BP7Pkiq7icz8fWvI51pJP5fILAu%2FPXQbL9vpBXgjMIcgZCMOBbHn1kILrzNKK%2Fkck%2BzhWn%2F6RiNs3RyC4ynv3bxtk0R4O29AIP8%2F%2Bkh%2Bx9cnqE4qJA5P85EPY%2BOT2Sy8518S3ebyb6fXEl4hj%2BGtaC0k5Q2J3hYWjkVRgzyMUHBlH2gezYqyp7lbhslbJXRZet0ghBEAv74tkjZPnienpASCJusxohiMOF4AEhzBY32SOCF0RBGFj3T4Pg6elvTo8IXl5Upa7mtEXweCyF5nNq0HWvdUi3oc5svU%2BkIcrSMpvT3ql3QPDKoswqi45OvSe8JM%2FPTqnAknkhX8KklJbVs9PDVwRPBnkpizl9c3pI%2FcdLolglysIBgpdmaZBFFhYInshEIkoLh85cUxPytNS5tloP9R5Hx6KW40gsNQyX7mzyO0wKO5wcwYuEiFIbS1ISKq2tlFDReioKZWyHU9Ix40G0iLIiJZWq7GxTcXkq4ZrbJjUpybm0%2By7Vj6eVKpWt%2B4GqoKqCKrVwPRVHVuS2yQOZrPSiDqZSoowsgqdaoozEsYWpmCgj2sYtut4XpnLKq1hx28FuCkcnyk7sk%2Fuc9O4tHtxncHTeAVRU%2Fu4Gbk1rfAa7G%2FqxuVf7UyN7Bqd6czStGTpZKgb%2BT%2Bogfz%2FBTrbDlxW3qm0Mg2vTDqaRA4Ov8paZZ4SN7LpGbYbXYVRHBj82dft4K8vd9P%2BNaUcG%2Fk7tjYJPv%2FoM7k1hRsPAvzOjmUz6DD4Vp3Y8MbiW7Sh71TQM%2FF9U86zGupRwp07KZzDIdtgMqq81A%2F8HMgrXpjE9%2FHw0h9qfmXGQ3euxMI3%2Fxe58oRXQC4L%2FW31UA9ypF7g3R9n6DCbCYDJuiZ8RhAVeEYKtfVbeCFlSgiPwbSLiXh0tHhBPV1gQztac3m%2BXjN5pt8FadTx5scLJ5O%2BSpkTX9jIEsWQ5gnDolYRXtCC61lsi5Nw67aJCSBdIIcQLpBGiBdojJAv04PhWoM67aNrigJAtGrR4XH15iAZBLNqzOK6%2BPUTr2Bzj3t%2BO8Io%2BObemJ9l1vgfCjm0fEaIlOyGES%2Fbs3K8XhBU7Ow7BK4JsGgg5Pw5Qnoq63BTqrVb9Fd%2Bm1Msjeoj3fv3Hn3X1hKXgDQAA%22%7D"
import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@20.16.5_babel-plugin-macros@3.1.0_sass@1.7_rqbbf4tbztzkhlgsexxzkssfyq/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2Flibs%2Ftoby%2Fdist%2Fcomponents%2Fpanel%2Fpanel.css.js.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA51U246bMBB936%2FwS6VEiisT2A1xv8ZgQ9wYD3GchKTqv1cY6I5zQds%2BAXPOGY7nYu4APPn1RgilXcpct805SfOs7X6g2JaTdZ7HMcFJlrM%2B9vvt%2BxAqokQZJ2fhFtMnW2L1ewwmEfgRg%2BsI3MRgGsACOnrUN21rTgpwUjlaQLDrnbBH7TXYSbff5Iol62sQwlm5ysCFk52WUtk%2B1kIsGB2viFNGeH1WQblTut75mPMREA9tHN6sCGu70aj30HDCUNnKqGzvnFS6U7Jn36i2UnWcZIwlSCGDwqjKx5lUiLvBGAaqADSioxct%2FS62ly8Rs55jbjFzN8cUmKkDc2RhWz9fAXsMJIx9Q5iZwZqAhZ5X4Bo%2BvBrhVSoXtCevCFsRht3Z%2F9DAnOa5pP13ySFIClHuawcnK2kJBlxc6GwVjbVZhjGT%2BtgaceWkMiqsQf%2BkUjtVDpNdgjk1Fs%2FxUMmooVPocyGmDUAmXTD5alKHu2VcSYc3ZvCb4vOeMHmY7pfcM7fgF1w1rb8ux0l0tbZUW6Otult2G6rSCim1rZ9TWpz98pB90hYGyj09euHu3K0POEEXRH3VOUnii0acPCDmLUr%2F1Nohco%2BuF3S6v%2FkSFhLet%2FX%2B%2F1%2F45WfK5KEcY5MevAzX8ddbEco5e9zXzfoD1Twnc7wGAAA%3D%22%7D"
import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@20.16.5_babel-plugin-macros@3.1.0_sass@1.7_rqbbf4tbztzkhlgsexxzkssfyq/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcore%2Fvars.css.ts.vanilla.css%22%2C%22source%22%3A%22OnJvb3QgewogIC0tbzYzMTFiMDogNDhweDsKICAtLW82MzExYjE6IHZhcigtLWs3OGUwMTJ3KTsKICAtLW82MzExYjI6IDY0cHg7CiAgLS1vNjMxMWIzOiB2YXIoLS14MzByeDk5KTsKICAtLW82MzExYjQ6IHZhcigtLXgzMHJ4OTgpOwogIC0tbzYzMTFiNTogMTM2OHB4Owp9%22%7D"
import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@20.16.5_babel-plugin-macros@3.1.0_sass@1.7_rqbbf4tbztzkhlgsexxzkssfyq/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcore%2Flayout%2Fpage-layout%2Fpage-layout.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41Ty3LCMAy85yt86Qw9uJO0FKi59FecRIBaWzaxaEg7%2FHsnTsOEEJhe8lppvdpVnmps9lufip9EiBKDN7pRYmPguE5EvCuR9Y%2ByxAoKRkdKFM4cLK2TU%2FLUUTxHCind4iXL8lclFvPUHwcFL%2BOCLE0fBvhrxAtHrJGgkqQtKNFh8%2FUFxI0HJZAMEsiA39DC2uCWZACzUaIAYqhGI1h9lDWWvFPiS1ezs5LHFvwDRpoWihzPFFjPzWPU53VZIm1l7pid7Zk%2BlytIs%2BevSNWXsPMT%2BJl7eZP7utEPG1exuBsXGWwYzjvtwmSyU3H%2BIXWlvRLtNX5yxNFmJYLVxnScH4fAuGlkGwsQD4%2B76%2FTZQeOKz9Gc%2B4uKLuBRCd2J662z0QXsptJ5cObAcT36wNL25Vsile1izAfdeuDr2MB%2FTTQd%2BC2x%2Be1%2FbjqZO4bvALc77o84K5qw7pS8WyhRi1BUACQ0lWJmkfrplouVP3breLFu18IqV68jcKUreF2AzIFrgKj8lJx%2BAe1mwrFpBAAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var pageContent = _7a468({defaultClassName:'wiyqgp5',variantClassNames:{removePadding:{false:'wiyqgp6'},pageHasTabs:{true:'wiyqgp7'}},defaultVariants:{},compoundVariants:[]});
export var pageContentContainer = 'wiyqgp4';
export var pageFooter = _7a468({defaultClassName:'wiyqgp8',variantClassNames:{fixedToBottom:{true:'wiyqgp9'}},defaultVariants:{},compoundVariants:[]});
export var pageHeader = 'wiyqgpa';
export var pageLockedMessage = 'wiyqgpb';
export var pageStyles = _7a468({defaultClassName:'wiyqgp0',variantClassNames:{size:{'default':'wiyqgp1',narrow:'wiyqgp2',fullWidth:'wiyqgp3'}},defaultVariants:{},compoundVariants:[]});